var $ = require('jquery');

$(function() {
    var $header = $('#header');

    // Nav Fixed
    $(window).scroll(function() {
        if ($(window).scrollTop() > 100) {
            $header.addClass('is-fixed');
        } else {
            $header.removeClass('is-fixed');
        }

        $(".scroll").each(function(){
            var imgPos = $(this).offset().top;    
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > imgPos - windowHeight + 100){
              $(this).addClass("is_animation");
            }
        });
    });

    // Nav Toggle Button
    $('#toggle').click(function(){
        $header.toggleClass('is-open');
    });

    $('a[href^="#"]').click(function() {
        // スクロールの速度
        var speed = 400; // ミリ秒
        // アンカーの値取得
        var href= $(this).attr("href");
        // 移動先を取得
        var target = $(href == "#" || href == "" ? 'html' : href);
        // 移動先を数値で取得
        var position = target.offset().top;
        // スムーススクロール
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
     });
});

var $delayTime = 1000;

$(window).on('load', function(){
    var $loadingAnim = $('#loading'),
        $body = $('body');
    
        setTimeout( function(){
            
        $body.addClass('loaded');
    
        $loadingAnim.find('.loading-a').on('transitionend', function( e ){ 
            $(this).parent().remove();
        });
    }, $delayTime );
});
